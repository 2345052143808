@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500);
/* html, body {
  min-height : 100%;
  overflow-x : hidden;
  overflow-y : auto;
  height: 100vh;
} */
body {
  margin          : 0;
  padding         : 0;
  font-family     : 'Roboto', 'Helvetica Neue', 'Helvetica', sans-serif;
  background-color: #f0f1eb;
}
.public_fixedDataTable_header,
.public_fixedDataTable_header .public_fixedDataTableCell_main {
  background     : #231f20;
  font-size      : 0.9em;
  font-weight    : 800;
  text-transform : uppercase;
  letter-spacing : 0.15em;
  color          : #FFF;
}

.public_fixedDataTableRow_main {
  border-bottom : 1px solid #DEDEDE;
}

.public_fixedDataTableRow_highlighted,
.public_fixedDataTableRow_highlighted .public_fixedDataTableCell_main {
  background-color : rgba(255, 255, 255, 0.5);
}

.public_fixedDataTableRow_even,
.public_fixedDataTableRow_even .public_fixedDataTableCell_main {
  background-color : rgba(255, 255, 255, 0.3);
}

a {
  cursor : pointer;
}

.clientmenu li:hover{
  
  background: #f1f1f1 !important;
}

.input-group-addon, button.close {
  font-family : 'Helvetica Neue', 'Helvetica', 'sans-serif';
}

.nav-tabs > li > a {
  font-size        : 1.2em;
  font-weight      : 800;
  text-transform   : uppercase;
  background-color : rgb(240, 241, 235);
  color            : #333;
}

.nav-tabs > li > a:hover {
  background-color : rgba(255, 255, 255, 0.8);
  border-color     : rgba(255, 255, 255, 0.6);
}

.popover-content {
  background-color : rgba(255, 255, 255, 0.8);
}

.date-picker-popover {
  height : 265px;
  width  : 250px;
}

.date-picker-popover td.text-muted {
  color : #DDD;
}

.date-picker-popover td.text-muted small{
  color : #333;
}

.heatmap-canvas {
  opacity : 0.6;
}

@media (min-width: 1170px) {
  .container {
    width : 97%;
  }
}

@media print {
  #root, [data-reactroot], body, html {
    height: auto;
  }

  body {
    -webkit-print-color-adjust: exact;
  }

  .no-print {
    display: none !important;
  }
}

/* Client Feedback */
.fix {
  position: fixed;
  bottom: 122px;
  right: 20px;
}

.fixed {
  position: fixed;
  bottom: 122px;
  right: 20px;
}

.form-floating {
  padding: 0.5rem;
}

.card {
  max-width: 400px !important;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border: 0 !important;
  border-radius: 0 0 0.4rem 0.4rem !important;
  background-color: #f1f1f1;
  cursor: pointer !important;
}

.img-responsive {
  height: 25px;
  width: 25px;
  cursor: pointer;
}

.card-header {
  font-size: 16px;
  font-weight: 400;
  padding: 0.8rem 0.6rem !important;
  margin-bottom: 0 !important;
  color: #f4f4f4 !important;
  background-color: #1e1e1e !important;
  border-radius: 0.4rem 0.4rem 0 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
}

.card-footer {
  color: #1e1e1e !important;
  margin-top: -4px !important;
  border-top: 1px solid #0000002d !important;
}

.form-floating {
  padding: 0.5rem;
}

::-webkit-input-placeholder {
  font-size: 14px;
  font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', sans-serif !important;
  letter-spacing: normal;
}

:-ms-input-placeholder {
  font-size: 14px;
  font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', sans-serif !important;
  letter-spacing: normal;
}

::placeholder {
  font-size: 14px;
  font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', sans-serif !important;
  letter-spacing: normal;
}

textarea {
  width: 100%;
  border: 1px solid #b5b4b4 !important;
  background-color: #fff !important;
  padding: 0.5rem !important;
  border-radius: 5px;
  outline: none;
  box-sizing: border-box;
  font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', sans-serif !important;
  letter-spacing: normal;
}

textarea:focus {
  border-color: #b5b4b4 !important;
  border-width: 1px !important;
  box-shadow: none;
  height: 200px;
  overflow-y: scroll !important;
}

.btn-save {
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #f3212e;
  color: #f3212e !important;
  cursor: pointer;
  padding: 0.4rem 0.5rem !important;
  border-radius: 0.25rem !important;
  margin-right: 0.5rem;
}

.btn-cancel {
  font-size: 14px;
  font-weight: 500;
  border: 1px solid #f3212e;
  cursor: pointer;
  color: #f3212e !important;
  padding: 0.4rem 0.5rem !important;
  border-radius: 0.25rem !important;
}

.action {
  float: right;
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 8px;
}

/*-- History  ---*/

.app-wrapper {
	max-width: 400px !important;
  width: 100%;
	margin: auto;
  }

.app-message-body {
	background-color: #f1f1f1;
	padding: 10px 14px 0;
	font-size: 12px;
	overflow-y: auto;
	max-height: 45vh;
	border-radius: 0 0 0.5rem 0.5rem;
	
  }
  .app-message-body .message-wrapper {
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
  }
  .app-message-body .message-wrapper:nth-child(2n+1) {
	-webkit-align-items: flex-start;
	        align-items: flex-start;
  }
  .app-message-body .message-wrapper:nth-child(2n+1) .message-meta {
	-webkit-justify-content: flex-start;
	        justify-content: flex-start;
  }
  .app-message-body .message-wrapper:nth-child(2n) {
	-webkit-align-items: flex-end;
	        align-items: flex-end;
  }
  .app-message-body .message-wrapper:nth-child(2n) .message-meta {
	-webkit-flex-direction: row-reverse;
	        flex-direction: row-reverse;
  }
  
  .message {
	max-width: 100%;
	background-color: #fff;
	padding: 8px;
	border-radius: 5px;
	box-shadow: 4px 4px rgba(200, 200, 200, 0.25);
	font-size: 14px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex: 1 1 auto;
	        flex: 1 1 auto;
  }
  .message-meta {
	display: -webkit-flex;
	display: flex;
	-webkit-flex: 1 1 auto;
	        flex: 1 1 auto;
	margin: 2px;
  }
  .message-meta .sender-avatar {
	width: 24px;
	height: 24px;
	border-radius: 9999px;
	background: linear-gradient(180deg, #fb551c, #FD1D1D);
	color: #fff;
	line-height: 24px;
	text-align: center;
	font-size: 0.666rem;
	font-weight: 600;
  }
  .message-meta .timestamp {
	color: #999;
	font-size: 12px;
	font-weight: 500;
	line-height: 20px;
	display: inline-block;
	margin: 0 5px;
  }
  
  /*--- STICKY POPUP ----*/

.sticky-icon {
	z-index: 1;
	position: fixed;
	bottom: 40px;
	right: 0%;
	width: 165px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	        flex-direction: column;
  cursor: pointer;
}

.sticky-icon p {
	-webkit-transform: translate(120px, 0px);
	        transform: translate(120px, 0px);
	border-radius: 50px 0px 0px 50px;
	text-align: left;
	text-decoration: none;
	text-transform: uppercase;
	padding: 6px;
	font-size: 20px;
	font-family: 'Roboto', sans-serif;
	transition: all 0.8s;
}

.feedback-text {
	font-size: 0.7rem;
	font-weight: bold;
	letter-spacing: 1px;
	position: relative;
  top: -0.72rem;
  cursor: pointer;
}

.sticky-icon p:hover {
	color: #FFF;
	-webkit-transform: translate(0px, 0px);
	        transform: translate(0px, 0px);
}

.sticky-icon p:hover img {
	-webkit-transform: rotate(360deg);
	        transform: rotate(360deg);
}

.feedback {
	background-color: #f3212e;
	color: #FFF;
}

.sticky-icon p img{	
	height: 35px!important;
	width: 35px!important;
	color: #f3212e;
	text-align: center;
	line-height: 32px;
	border-radius: 50%;
	margin-right: 10px;
	transition: all 0.5s;
  margin-top: 5px;
}


